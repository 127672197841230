<template>
  <v-container id="products-profit-qty-tables" fluid tag="section">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @input="doSearch"
      >
      </v-text-field>
    </v-card-title>
    <v-data-table
      ref="vDataTable"
      :headers="headers"
      :items="orderProducts"
      hide-default-footer
      class="elevation-0"
    >
      <template #item="{ item, index, headers }">
        <tr>
          <td>
            {{ index + 1 + (page - 1) * 10 }}
          </td>
          <td>
            {{ item.title }}
          </td>
          <td>
            {{ item.quantity }}
          </td>
          <td>
            <v-text v-if="item.profit_and_loss_qty <= 0" class="red--text"
              >Rp. {{ item.profit_and_loss_qty }}</v-text
            >
            <v-text v-else>Rp. {{ item.profit_and_loss_qty }} </v-text>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        @input="go"
        :length="lengthPage"
        :total-visible="5"
        color="#1b5e20"
      ></v-pagination>
    </div>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        >
        </v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      orderProducts: [],
      keyword: "",
      page: 0,
      lengthPage: 0,
      headers: [
        { text: "No", value: "id" },
        { text: "Name", value: "title" },
        { text: "Qty", value: "quantity" },
        { text: "Profit Product", value: "profit_and_loss_qty" },
      ],
      progressBar: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/profit_by_producy_qtyOrderByQty?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.orderProducts = data;
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/orders/search/profit_by_producy_qtyOrderByQty/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.orderProducts = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
  },
  created() {
    this.go();
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
