<template>
  <v-container id="products-draft-tables" fluid tag="section">
    <v-card-title>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.title"
                      label="Product Name"
                      required
                      :rules="nameRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <label>Cover Image</label>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <img
                      :src="getImage('/' + editedItem.cover)"
                      height="100px"
                      width="100px"
                      contain
                    />
                    <v-file-input
                      truncate-length="15"
                      @change="onFileSelected"
                      ref="fileImage"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="categoryKeyword"
                      append-icon="mdi-magnify"
                      label="Search Category"
                      single-line
                      hide-details
                      @input="doSearchCategory"
                    >
                    </v-text-field>
                    <v-select
                      v-model="editedItem.category"
                      :items="categories"
                      attach
                      chips
                      ref="categoryReff"
                      label="Select Category"
                      required
                      :rules="categoryRules"
                      item-text="name"
                      item-value="id"
                      :disabled="!isDisabled"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Description"
                      required
                      :rules="descriptionRules"
                      height="100"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.stock"
                      label="Stock"
                      type="number"
                      :rules="stockRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.weight"
                      label="Weight"
                      type="number"
                      required
                      :rules="weightRules"
                      suffix="gr"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.buying_price"
                      label="Buying Price"
                      type="number"
                      required
                      :rules="buying_priceRules"
                      prefix="Rp"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-row>
                      <v-col cols="8" sm="6" md="8">
                        <v-text-field
                          v-model="editedItem.price"
                          label="Selling Price"
                          type="number"
                          required
                          :rules="priceRules"
                          prefix="Rp"
                          ref="price"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" sm="6" md="4">
                        <v-text-field
                          v-model="presentase"
                          label="Margin Selling Price"
                          suffix="%"
                          type="number"
                          @input="countMargin"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#1b5e20" class="white--text" elevation="2" @click="close">
              Cancel
            </v-btn>
            <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <div class="warning text-center">
            <header class="text-h5">Confirmation</header>
          </div>
          <v-card-title class="text-h6"
            >Are you sure you want to delete this {{ editedItem.title }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#1b5e20" class="white--text" elevation="2" @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn
              color="#1b5e20"
              class="white--text"
              elevation="2"
              @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="keyword"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @input="doSearch"
      >
      </v-text-field>
    </v-card-title>

    <v-data-table
      ref="vDataTable"
      :headers="headers"
      :items="products"
      hide-default-footer
      class="elevation-0"
    >
      <template #item="{ item, index, headers }">
        <tr>
          <td>
            {{ index + 1 + (page - 1) * 10 }}
          </td>
          <td>
            <v-list-item-avatar>
              <v-img :src="getImage('/' + item.cover)" :alt="item.name"></v-img>
            </v-list-item-avatar>
          </td>

          <td>
            {{ item.title }}
          </td>
          <td>
            {{ item.buying_price }}
          </td>
          <td>
            {{ item.price }}
          </td>
          <td>
            {{ item.weight }}
          </td>
          <td>
            {{ item.stock }}
          </td>
          <td>
            <v-chip :color="getColor(item.status)" dark>
              {{ item.status }}
            </v-chip>
          </td>
          <td>
            {{ item.views }}
          </td>
          <td>
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        @input="go"
        :length="lengthPage"
        :total-visible="5"
        color="#1b5e20"
      ></v-pagination>
    </div>

    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        >
        </v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data: () => ({
    valid: true,
    isDisabled: false,
    products: [],
    keyword: "",
    page: 0,
    lengthPage: 0,
    headers: [
      { text: "No", value: "id" },
      { text: "Icon", value: "image" },
      { text: "Name Product", value: "title" },
      { text: "Buying Price", value: "buying_price" },
      { text: "Price", value: "price" },
      { text: "Weight", value: "weight" },
      { text: "Stock", value: "stock" },
      { text: "Status", value: "status" },
      { text: "View", value: "views" },
      { text: "Actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      title: "",
      image: "",
      category: "",
      description: "",
      stock: "",
      weight: "",
      buying_price: "",
      price: "",
    },
    defaultItem: {
      title: "",
      image: "",
      category: "",
      description: "",
      stock: "",
      weight: "",
      buying_price: "",
      price: "",
    },
    selectedFile: null,
    progressBar: false,
    nameRules: [
      (v) => !!v || "Category Name is required",
      (v) => (v && v.length <= 20) || "Max 20 characters",
    ],
    //imageRules: [v => !!v || 'Cover Image is required'],
    categoryRules: [(v) => !!v || "Category is required"],
    descriptionRules: [(v) => !!v || "Description is required"],
    stockRules: [(v) => /([0-9])/.test(v) || "Stock must be valid"],
    weightRules: [
      (v) => !!v || "Weight is required",
      (v) => /([0-9])/.test(v) || "Weight must be valid",
    ],
    priceRules: [
      (v) => !!v || "Price is required",
      (v) => /([0-9])/.test(v) || "Price must be valid",
    ],
    buying_priceRules: [
      (v) => !!v || "Buying Price is required",
      (v) => /([0-9])/.test(v) || "Buying Price must be valid",
    ],
    categories: [
      {
        value: "id",
      },
      {
        value: "name",
      },
    ],
    categoryKeyword: "",
    presentase: "",
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.go();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/productsDraft?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.products = data;
          // jumlah halaman di dapat dari meta endpoint products
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    getColor(status) {
      if (status == "PUBLISH") return "green";
      else return "red";
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/products/searchDraft/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.products = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    editItem(item) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //setCategory
      let keyword = this.editedItem.id;
      this.isDisabled = true;
      this.axios
        .get("/categories/categoryByProductSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.categories = data;
          this.editedItem.category = data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
      this.dialog = true;
      this.$refs.categoryReff.update();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/products/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.products.splice(this.editedIndex, 1);
          this.closeDelete();
          this.products.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.products.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("name", this.editedItem.title);
        formData.set("cover", this.selectedFile);
        formData.set("user_id", this.user.id);
        formData.set("id", this.editedItem.id);
        formData.set("category", this.editedItem.category);
        formData.set("description", this.editedItem.description);
        formData.set("stock", this.editedItem.stock);
        formData.set("weight", this.editedItem.weight);
        formData.set("buying_price", this.editedItem.buying_price);
        formData.set("price", this.editedItem.price);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        if (this.editedIndex > -1) {
          Object.assign(this.products[this.editedIndex], this.editedItem);
          this.axios
            .post("/products/update", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.products.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        }
      }
    },
    onFileSelected(event) {
      this.selectedFile = event;
    },
    clear() {
      this.$refs.fileImage.reset();
      this.go();
      this.close();
      this.progressBar = false;
    },
    doSearchCategory() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.categoryKeyword;
      if (keyword.length > 0) {
        this.isDisabled = true;
        this.axios
          .get("/categories/ajaxSearch/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.categories = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.isDisabled = false;
      }
      this.$refs.categoryReff.update();
    },
    countMargin() {
      let a = parseInt(this.editedItem.buying_price);
      let b = parseInt(this.presentase) / 100;
      this.editedItem.price = a + a * b;
      this.$refs.price.update();
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
