<template>
  <v-container id="products-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" class="px-5 py-3">
      <v-tabs color="#1b5e20">
        <v-tab @click="go()">Products Active</v-tab>
        <v-tab>Products Draft</v-tab>
        <v-tab>Product Profit/Price</v-tab>
        <v-tab>Product Profit/Qty</v-tab>
        <v-tab>Move Category Product</v-tab>
        <v-tab-item>
          <v-card-title>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#1b5e20"
                  class="white--text"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  >Add Product</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.code"
                            label="Code"
                            :rules="codeRules"
                            disabled
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.title"
                            label="Product Name"
                            required
                            :rules="nameRules"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <label>Cover Image</label>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <img
                            :src="getImage('/' + editedItem.cover)"
                            height="100px"
                            width="100px"
                            contain
                          />
                          <v-file-input
                            truncate-length="15"
                            @change="onFileSelected"
                            ref="fileImage"
                          >
                          </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="categoryKeyword"
                            append-icon="mdi-magnify"
                            label="Search Category"
                            single-line
                            hide-details
                            @input="doSearchCategory"
                          >
                          </v-text-field>
                          <v-select
                            v-model="editedItem.category"
                            :items="categories"
                            attach
                            chips
                            ref="categoryReff"
                            label="Select Category"
                            required
                            :rules="categoryRules"
                            item-text="name"
                            item-value="id"
                            :disabled="!isDisabled"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-textarea
                            v-model="editedItem.description"
                            label="Description"
                            required
                            :rules="descriptionRules"
                            height="100"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.stock"
                            label="Stock"
                            type="number"
                            :rules="stockRules"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.weight"
                            label="Weight"
                            type="number"
                            required
                            :rules="weightRules"
                            suffix="gr"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-text-field
                            v-model="editedItem.buying_price"
                            label="Buying Price"
                            type="number"
                            required
                            :rules="buying_priceRules"
                            prefix="Rp"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="12">
                          <v-row>
                            <v-col cols="8" sm="6" md="8">
                              <v-text-field
                                v-model="editedItem.price"
                                label="Selling Price"
                                type="number"
                                required
                                :rules="priceRules"
                                prefix="Rp"
                                ref="price"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="6" md="4">
                              <v-text-field
                                v-model="presentase"
                                label="Margin Selling Price"
                                suffix="%"
                                type="number"
                                @input="countMargin"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" sm="6" md="12">
                          <v-row>
                            <v-col cols="8" sm="6" md="8">
                              <v-text-field
                                v-model="editedItem.price_product_tokopedia"
                                label="Selling Price TOKOPEDIA"
                                type="number"
                                required
                                prefix="Rp"
                                ref="price_product_tokopedia"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="6" md="4">
                              <v-text-field
                                v-model="presentaseTOKOPEDIA"
                                label="Margin Selling Price TOKOPEDIA"
                                suffix="%"
                                type="number"
                                @input="countMarginTOKOPEDIA"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="8" sm="6" md="8">
                              <v-text-field
                                v-model="editedItem.price_product_shopee"
                                label="Selling Price SHOPEE"
                                type="number"
                                required
                                prefix="Rp"
                                ref="price_product_shopee"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="6" md="4">
                              <v-text-field
                                v-model="presentaseSHOPEE"
                                label="Margin Selling Price SHOPEE"
                                suffix="%"
                                type="number"
                                @input="countMarginSHOPEE"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="8" sm="6" md="8">
                              <v-text-field
                                v-model="editedItem.price_product_bukalapak"
                                label="Selling Price BUKALAPAK"
                                type="number"
                                required
                                prefix="Rp"
                                ref="price_product_bukalapak"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="6" md="4">
                              <v-text-field
                                v-model="presentaseBUKALAPAK"
                                label="Margin Selling Price BUKALAPAK"
                                suffix="%"
                                type="number"
                                @input="countMarginBUKALAPAK"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="8" sm="6" md="8">
                              <v-text-field
                                v-model="editedItem.price_product_lazada"
                                label="Selling Price LAZADA"
                                type="number"
                                required
                                prefix="Rp"
                                ref="price_product_lazada"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="6" md="4">
                              <v-text-field
                                v-model="presentaseLAZADA"
                                label="Margin Selling Price LAZADA"
                                suffix="%"
                                type="number"
                                @input="countMarginLAZADA"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" sm="6" md="12" style="display: none">
                          <!-- <v-text-field v-model="productKeyword" append-icon="mdi-magnify"
                              label="Search Product promotion" single-line hide-details @input="doSearchProduct">
                            </v-text-field> -->
                          <v-select
                            v-model="editedItem.productIdX"
                            :items="productsChilds"
                            chips
                            multiple
                            item-text="title"
                            item-value="id"
                            ref="productNameReff"
                            label="Select Product Component"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#1b5e20" class="white--text" elevation="2" @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="#1b5e20" class="white--text" elevation="2" @click="save">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <div class="warning text-center">
                  <header class="text-h5">Confirmation</header>
                </div>
                <v-card-title class="text-h6"
                  >Are you sure you want to delete this {{ editedItem.title }} ?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="#1b5e20"
                    class="white--text"
                    elevation="2"
                    @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="keyword"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @input="doSearch"
            >
            </v-text-field>
          </v-card-title>

          <v-data-table
            ref="vDataTable"
            :headers="headers"
            :items="products"
            hide-default-footer
            class="elevation-0"
          >
            <template #item="{ item, index, headers }">
              <tr>
                <td>
                  {{ index + 1 + (page - 1) * 10 }}
                </td>
                <td>
                  <v-list-item-avatar>
                    <v-img :src="getImage('/' + item.cover)" :alt="item.name"></v-img>
                  </v-list-item-avatar>
                </td>
                <td>
                  {{ item.code }}
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.buying_price }}
                </td>
                <td>
                  {{ item.price }}
                </td>
                <td>
                  {{ item.price_product_tokopedia }}
                </td>
                <td>
                  {{ item.price_product_shopee }}
                </td>
                <td>
                  {{ item.price_product_bukalapak }}
                </td>
                <td>
                  {{ item.price_product_lazada }}
                </td>
                <td>
                  {{ item.weight }}
                </td>
                <td>
                  {{ item.stock }}
                </td>
                <td>
                  <v-chip :color="getColor(item.status)" dark>
                    {{ item.status }}
                  </v-chip>
                </td>
                <td>
                  {{ item.views }}
                </td>
                <td>
                  <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination
              v-model="page"
              @input="go"
              :length="lengthPage"
              :total-visible="5"
              color="#1b5e20"
            ></v-pagination>
          </div>
        </v-tab-item>
        <v-tab-item>
          <ProductsDraft @click="go()" />
        </v-tab-item>
        <v-tab-item>
          <ProfitProduct @click="go()" />
        </v-tab-item>
        <v-tab-item>
          <ProfitProductOrderByQty @click="go()" />
        </v-tab-item>
        <v-tab-item>
          <MoveCategoryProduct @click="go()" />
        </v-tab-item>
      </v-tabs>
    </base-material-card>
    <div class="text-center">
      <v-dialog v-model="progressBar" width="100" hide-overlay>
        <v-progress-circular
          :size="60"
          color="primary"
          indeterminate
          align-center
          justify-center
        ></v-progress-circular>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import ProductsDraft from "@/views/dashboard/pages/product/ProductsDraft.vue";
import ProfitProduct from "@/views/dashboard/pages/product/ProfitProduct.vue";
import ProfitProductOrderByQty from "@/views/dashboard/pages/product/ProfitProductOrderByQty.vue";
import MoveCategoryProduct from "@/views/dashboard/pages/product/MoveCategoryProduct.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ProfitProduct,
    ProductsDraft,
    ProfitProductOrderByQty,
    MoveCategoryProduct,
  },
  data: () => ({
    valid: true,
    isDisabled: false,
    products: [],
    keyword: "",
    page: 0,
    lengthPage: 0,
    headers: [
      { text: "No", value: "id" },
      { text: "Icon", value: "image" },
      { text: "Code", value: "code" },
      { text: "Name Product", value: "title" },
      { text: "Buying Price", value: "buying_price" },
      { text: "Price", value: "price" },
      {
        text: "Price TPD",
        value: "price_product_tokopedia",
      },
      { text: "Price SHP", value: "price_product_shopee" },
      { text: "Price BKL", value: "price_product_bukalapak" },
      { text: "Price LZA", value: "price_product_lazada" },
      { text: "Weight", value: "weight" },
      { text: "Stock", value: "stock" },
      { text: "Status", value: "status" },
      { text: "View", value: "views" },
      { text: "Actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      code: "",
      title: "",
      image: "",
      category: "",
      description: "",
      stock: "",
      weight: "",
      buying_price: "",
      price: "",
      price_product_tokopedia: "",
      price_product_shopee: "",
      price_product_bukalapak: "",
      price_product_lazada: "",
      productIdX: "",
    },
    defaultItem: {
      code: "",
      title: "",
      image: "",
      category: "",
      description: "",
      stock: "",
      weight: "",
      buying_price: "",
      price: "",
      price_product_tokopedia: "",
      price_product_shopee: "",
      price_product_bukalapak: "",
      price_product_lazada: "",
      productIdX: "",
    },
    selectedFile: null,
    progressBar: false,
    nameRules: [
      (v) => !!v || "Category Name is required",
      (v) => (v && v.length <= 30) || "Max 30 characters",
    ],
    //imageRules: [v => !!v || 'Cover Image is required'],
    categoryRules: [(v) => !!v || "Category is required"],
    descriptionRules: [(v) => !!v || "Description is required"],
    stockRules: [(v) => /([0-9])/.test(v) || "Stock must be valid"],
    weightRules: [
      (v) => !!v || "Weight is required",
      (v) => /([0-9])/.test(v) || "Weight must be valid",
    ],
    priceRules: [
      (v) => !!v || "Price is required",
      (v) => /([0-9])/.test(v) || "Price must be valid",
    ],
    buying_priceRules: [
      (v) => !!v || "Buying Price is required",
      (v) => /([0-9])/.test(v) || "Buying Price must be valid",
    ],
    categories: [
      {
        value: "id",
      },
      {
        value: "name",
      },
    ],
    categoryKeyword: "",
    presentase: "",
    presentaseTOKOPEDIA: "",
    presentaseSHOPEE: "",
    presentaseBUKALAPAK: "",
    presentaseLAZADA: "",
    productsChilds: [
      {
        value: "id",
      },
      {
        value: "title",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Product" : "Edit Product";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    
    this.go();
    this.doSearchProduct();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      this.progressBar = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/products?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.products = data;
          // jumlah halaman di dapat dari meta endpoint products
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
          this.progressBar = false;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    getColor(status) {
      if (status == "PUBLISH") return "green";
      else return "red";
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/products/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.products = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.go();
      }
    },
    editItem(item) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      //setCategory
      let keyword = this.editedItem.id;
      this.isDisabled = true;
      this.axios
        .get("/categories/categoryByProductSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.categories = data;
          this.editedItem.category = data[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
      this.doSearchProduct();
      this.dialog = true;
      this.$refs.categoryReff.update();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.progressBar = true;
      let formData = new FormData();
      formData.set("id", this.editedItem.id);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/products/delete-permanent", formData, config)
        .then((response) => {
          let { data } = response;
          this.setAuth(data.data);
          this.setAlert({
            status: true,
            text: data.message,
            color: "success",
          });
          this.products.splice(this.editedIndex, 1);
          this.closeDelete();
          this.products.push(this.editedItem);
          this.go();
          this.progressBar = false;
        })
        .catch((error) => {
          let { data } = error;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
          this.products.push(this.editedItem);
          this.go();
          this.progressBar = false;
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate()) {
        this.progressBar = true;
        let formData = new FormData();
        formData.set("code", this.editedItem.code);
        formData.set("name", this.editedItem.title);
        formData.set("cover", this.selectedFile);
        formData.set("user_id", this.user.id);
        formData.set("id", this.editedItem.id);
        formData.set("category", this.editedItem.category);
        formData.set("description", this.editedItem.description);
        formData.set("stock", this.editedItem.stock);
        formData.set("weight", this.editedItem.weight);
        formData.set("buying_price", this.editedItem.buying_price);
        formData.set("price", this.editedItem.price);

        formData.set("price_product_tokopedia", this.editedItem.price_product_tokopedia);
        formData.set("price_product_shopee", this.editedItem.price_product_shopee);
        formData.set("price_product_bukalapak", this.editedItem.price_product_bukalapak);
        formData.set("price_product_lazada", this.editedItem.price_product_lazada);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.api_token,
          },
        };
        if (this.editedIndex > -1) {
          Object.assign(this.products[this.editedIndex], this.editedItem);
          this.axios
            .post("/products/update", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.products.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        } else {
          this.axios
            .post("/products/store", formData, config)
            .then((response) => {
              let { data } = response;
              this.setAuth(data.data);
              this.setAlert({
                status: true,
                text: data.message,
                color: data.status,
              });
              this.products.push(this.editedItem);
              this.clear();
            })
            .catch((error) => {
              let { data } = error;
              this.setAlert({
                status: true,
                text: data.message,
                color: "error",
              });
              this.clear();
            });
        }
      }
    },
    onFileSelected(event) {
      this.selectedFile = event;
    },
    clear() {
      this.$refs.fileImage.reset();
      this.go();
      this.close();
      this.clearForm();
      this.progressBar = false;
    },
    doSearchCategory() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.categoryKeyword;
      if (keyword.length > 0) {
        this.isDisabled = true;
        this.axios
          .get("/categories/ajaxSearch/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.categories = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.isDisabled = false;
      }
      this.$refs.categoryReff.update();
    },
    countMargin() {
      let a = parseInt(this.editedItem.buying_price);
      let b = parseInt(this.presentase) / 100;
      this.editedItem.price = a + a * b;
      this.$refs.price.update();
    },

    countMarginTOKOPEDIA() {
      let a = parseInt(this.editedItem.buying_price);
      let b = parseInt(this.presentaseTOKOPEDIA) / 100;
      this.editedItem.price_product_tokopedia = a + a * b;
      this.$refs.price_product_tokopedia.update();
    },

    countMarginSHOPEE() {
      let a = parseInt(this.editedItem.buying_price);
      let b = parseInt(this.presentaseSHOPEE) / 100;
      this.editedItem.price_product_shopee = a + a * b;
      this.$refs.price_product_shopee.update();
    },

    countMarginBUKALAPAK() {
      let a = parseInt(this.editedItem.buying_price);
      let b = parseInt(this.presentaseBUKALAPAK) / 100;
      this.editedItem.price_product_bukalapak = a + a * b;
      this.$refs.price_product_bukalapak.update();
    },

    countMarginLAZADA() {
      let a = parseInt(this.editedItem.buying_price);
      let b = parseInt(this.presentaseLAZADA) / 100;
      this.editedItem.price_product_lazada = a + a * b;
      this.$refs.price_product_lazada.update();
    },

    doSearchProduct() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.productKeyword;
      this.isDisabled = true;
      this.axios
        .get("/products/ajaxSearch/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.productsChilds = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearForm() {
      this.doSearchProduct();
      this.$refs.form.reset();
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.position-relative {
  position: relative;
}
</style>
